import { parseIntoGrantMask } from "common/permissions";
import { ref } from "@vue/composition-api";
export const currentUserGrantMask = ref(parseIntoGrantMask(["*"]));
/** Checks if the current user is granted the given permission. */
export function granted(permission) {
    let permMask = parseIntoGrantMask([permission]);
    return (currentUserGrantMask.value & permMask) > 0;
}
/** @deprecated A helper for checking grants. */
export class GrantChecker {
    constructor() {
        this.granted = granted;
    }
}
