import { useStore } from "./vuex-workaround";
export function add(toast) {
    const store = useStore();
    store.commit("toast/ADD_TOAST", toast);
}
export function remove(id) {
    const store = useStore();
    store.commit("toast/REMOVE_TOAST", id);
}
export default {
    add,
    remove,
};
