export class OttException extends Error {
}
export class PermissionDeniedException extends OttException {
    constructor(permission) {
        super(`Permission denied: ${permission}`);
        this.name = "PermissionDeniedException";
    }
}
export class InvalidRoleException extends OttException {
    constructor(role) {
        super(`Role ${role.toString()} (type: ${typeof role}) is not valid.`);
        this.name = "InvalidRoleException";
    }
}
export class InvalidTokenException extends OttException {
    constructor() {
        super(`Invalid or missing token.`);
        this.name = "InvalidTokenException";
    }
}
