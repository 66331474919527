import { defineComponent } from "@vue/composition-api";
import ToastNotification from "@/components/ToastNotification.vue";
import { useStore } from "@/util/vuex-workaround";
/**
 * Handles displaying all toast notifications.
 */
const Notifier = defineComponent({
    name: "Notifier",
    components: {
        ToastNotification,
    },
    setup() {
        const store = useStore();
        function closeAll() {
            store.commit("toast/CLEAR_ALL_TOASTS");
        }
        return {
            closeAll,
        };
    },
});
export default Notifier;
