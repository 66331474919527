let _store;
export function setStoreInstance(s) {
    _store = s;
}
/** This is a workaround to make it possible to convert components that
 * reference the Vuex store to use the composition api. This should be
 * easily replacable with `useStore()` in vuex 4. */
export function useStore() {
    return _store;
}
