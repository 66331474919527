import { defineComponent, ref, toRefs, onMounted, onUnmounted, computed, } from "@vue/composition-api";
import { ToastStyle } from "@/models/toast";
import { RoomRequestType } from "common/models/messages";
import { API } from "@/common-http";
import toasts from "@/util/toast";
import { useStore } from "@/util/vuex-workaround";
const ToastNotification = defineComponent({
    name: "ToastNotification",
    props: {
        toast: { type: Object },
        number: { type: Number },
    },
    setup(props) {
        let { toast } = toRefs(props);
        const store = useStore();
        let padding = ref(8);
        let closeTimeoutId = ref(null);
        onMounted(() => {
            if (toast.value.duration) {
                closeTimeoutId.value = setTimeout(() => {
                    close();
                }, toast.value.duration);
            }
        });
        onUnmounted(() => {
            if (closeTimeoutId.value) {
                clearTimeout(closeTimeoutId.value);
            }
        });
        let color = computed(() => {
            if (toast.value.style === ToastStyle.Success) {
                return "green";
            }
            else if (toast.value.style === ToastStyle.Error) {
                return "red";
            }
            return undefined;
        });
        let undoable = computed(() => {
            if (!toast.value.event) {
                return false;
            }
            let eventType = toast.value.event.request.type;
            return (eventType === RoomRequestType.SeekRequest ||
                eventType === RoomRequestType.SkipRequest ||
                eventType === RoomRequestType.AddRequest ||
                eventType === RoomRequestType.RemoveRequest);
        });
        async function undo() {
            try {
                await API.post(`/room/${store.state.room.name}/undo`, {
                    data: { event: toast.value.event },
                });
                close();
            }
            catch (err) {
                toasts.add({
                    style: ToastStyle.Error,
                    content: err.message,
                    duration: 4000,
                });
            }
        }
        function close() {
            toasts.remove(toast.value.id);
        }
        return {
            padding,
            color,
            undoable,
            undo,
            close,
            ToastStyle,
        };
    },
});
export default ToastNotification;
