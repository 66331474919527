export const captionsModule = {
    namespaced: true,
    state: {
        availableTracks: [],
    },
    mutations: {
        SET_AVAILABLE_TRACKS(state, payload) {
            state.availableTracks = payload.tracks;
        },
    },
};
